import * as XLSX from "xlsx";

import { MARKETPLACE_PORTAL_TITLE } from "@/common/constants";

export const downloadExcel = ({ filename, rows }) => {
    const data = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const parseFileMassiveLoadProductReferencesAndInventories = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const ab = event.target?.result;
            const wb = XLSX.read(ab, { type: "array" });
            const wsName = wb.SheetNames[0];
            if (wsName !== "products_inventories") {
                return reject(new Error("File is not equal to template"));
            }
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, {
                header: [
                    "name",
                    "sku",
                    "barcode",
                    "price",
                    "stock",
                    "size",
                    "color",
                    "lineSlug",
                    "brandSlug",
                    "cost"
                ],
                blankrows: false
            });
            const rows = data.slice(4);
            const result = rows.map((row) => ({
                index: row.__rowNum__ + 1,
                item: {
                    name: `${row.name}`,
                    sku: `${row.sku}`,
                    barcode: `${row.barcode}`,
                    size: row.size,
                    stock: Number(row.stock || 0),
                    price: Number(row.price || 0),
                    color: row.color,
                    lineSlug: row.lineSlug,
                    brandSlug: row.brandSlug,
                    cost: row.cost
                }
            }));
            resolve(result);
        };
        reader.readAsArrayBuffer(file);
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const parseFileMassiveLoadProductAndReferences = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const ab = event.target?.result;
            const wb = XLSX.read(ab, { type: "array" });
            const wsName = wb.SheetNames[0];
            if (wsName !== "products") {
                return reject(new Error("File is not equal to template"));
            }
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, {
                header: [
                    "name",
                    "sku",
                    "barcode",
                    "price",
                    "size",
                    "color",
                    "lineSlug",
                    "brandSlug",
                    "cost"
                ],
                blankrows: false
            });
            const rows = data.slice(4);
            const result = rows.map((row) => ({
                index: row.__rowNum__ + 1,
                item: {
                    name: `${row.name}`,
                    sku: `${row.sku}`,
                    barcode: `${row.barcode}`,
                    size: row.size,
                    price: Number(row.price || 0),
                    color: row.color,
                    lineSlug: row.lineSlug,
                    brandSlug: row.brandSlug,
                    cost: row.cost
                }
            }));
            resolve(result);
        };
        reader.readAsArrayBuffer(file);
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const parseFileMassiveLoadInventories = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const ab = event.target?.result;
            const wb = XLSX.read(ab, { type: "array" });
            const wsName = wb.SheetNames[0];
            if (wsName !== "inventories") {
                return reject(new Error("File is not equal to template"));
            }
            const ws = wb.Sheets[wsName];
            const data = XLSX.utils.sheet_to_json(ws, {
                header: ["barcode", "stock", "priceOffer", "cost"],
                blankrows: false
            });
            const rows = data.slice(4);
            const result = rows.map((row) => ({
                item: {
                    barcode: `${row.barcode}`,
                    stock: Number(row.stock || 1),
                    priceOffer: Number(row.priceOffer || 0),
                    cost: Number(row.cost || 0)
                },
                index: row.__rowNum__ + 1
            }));
            resolve(result);
        };
        reader.readAsArrayBuffer(file);
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const downloadFileMassiveInventory = async (fileUrl, filename, rows) => {
    const response = await fetch(fileUrl);
    const fileBuffer = await response.arrayBuffer();
    const wb = XLSX.read(fileBuffer, { type: "array" });
    const wsName = wb.SheetNames[0];
    const ws = wb.Sheets[wsName];
    const additionalHeaders = [
        [
            "Precio sin descuento",
            "Nombre de producto",
            "Sku de producto",
            "Tamaño",
            "Color",
            "Id de referencia",
            "Id de producto"
        ]
    ];
    XLSX.utils.sheet_add_aoa(ws, additionalHeaders, {
        origin: { c: 4, r: 1 }
    });
    XLSX.utils.sheet_add_aoa(ws, additionalHeaders, {
        origin: { c: 4, r: 3 }
    });
    XLSX.utils.sheet_add_aoa(
        ws,
        rows.map((row) => [
            row.barcode,
            row.stock,
            row.priceOffer,
            row.cost,
            row.price,
            row.name,
            row.sku,
            row.size,
            `${row.color} / ${row.colorRef}`,
            row.referenceId,
            row.productId
        ]),
        { origin: { c: 0, r: 4 } }
    );
    ws["!cols"] = [
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 25 },
        { width: 25 },
        {}
    ];
    ws["!rows"] = [{}, {}, { hpt: 50, wrapText: true }];
    XLSX.writeFile(wb, `${filename}.xlsx`, {
        Props: { Author: MARKETPLACE_PORTAL_TITLE }
    });
};
